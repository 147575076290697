<template>
    <div>
        <transition name="fade" appear>
            <div class="v-step-one-contract grid-dashboard area">
                <div class="v-step-one-contract-notification grid-item grid-dashboard grid-inputs">
                    <div class="wrapper-notifications steps">
                        <div class="wrapper-notifications-icon-step">
                            <svg>
                                <use xlink:href="../../assets/img/sprite.svg#icon-step1"></use>
                            </svg>
                        </div>
                        <p>Заполнение анкеты и согласие на обработку персональных данных</p>   
                    </div>
                    <div class="wrapper-notifications default">

                        <p>Если клиент не согласен с какими-либо утверждениями, то ему необходимо обратиться в фонд для дальнейшего оформления договора</p>

                    </div>
                </div>
                <div class="v-step-one-contract-title grid-item">
                    <p class="h2 type-2">Подтвердите информацию приведенную ниже со слов клиента</p>
                </div>
                <div class="v-step-one-contract-form grid-item">
                    <FormulateForm class="module-iip-1" name="firstForm" >
                        <div class="wrapper-checkbox-block area shadow-dark">
                            <p class="wrapper-checkbox-main-title">Клиент подтверждает, что он:</p>
                            <div class="wrapper-checkbox inline-block">
                                <label class="checkbox">
                                    <input 
                                        type="checkbox"
                                        v-model="data.pubPerson"
                                        validation="required"
                                    >
                                    <div></div>
                                    <p>не является иностранным публичным должностным лицом (ИПДЛ), должностным лицом публичных международных организаций (МПДЛ) или лицом, замещающим (занимающим) государственные должности Российской Федерации, должности членов Совета директоров Центрального банка Российской Федерации, должност федеральной государственной службы, назначение на которые и освобождение от которых осуществляются Президентом Российской Федерации, должности в Центральном банке Российской Федерации, государственных корпорациях и иных организациях, созданных Российской Федерацией на основании федеральных законов, включенные в перечни должностей, определяемые Президентом Российской Федерации (РПДЛ) и не является супругом (супругой)/близким родственником (родственником по прямой восходящей или нисходящей линии) ИПДЛ/МПДЛ/РПДЛ </p>
                                </label>
                            </div>
                            <div class="wrapper-checkbox inline-block">
                                <label class="checkbox">
                                    <input 
                                        type="checkbox"
                                        v-model="data.spouse"
                                        validation="required"
                                    >
                                    <div></div>
                                    <p>не является супругом (супругой)/близким родственником (родственником по прямой восходящей или нисходящей линии) ИПДЛ/МПДЛ/РПДЛ</p>
                                </label>
                            </div>
                            <div class="wrapper-checkbox inline-block">
                                <label class="checkbox">
                                    <input 
                                        type="checkbox"
                                        v-model="data.stableFinance"
                                        validation="required"
                                    >
                                    <div></div>
                                    <p>имеет стабильное финансовое положение, положительную деловую репутацию и законный источник происхождения денежных средств</p>
                                </label>
                            </div>
                        </div>
                        <div class="wrapper-checkbox-block area shadow-dark">
                            <div class="wrapper-checkbox inline-block">
                                <label class="checkbox">
                                    <input 
                                        type="checkbox"
                                        v-model="data.broadcast"
                                        validation="required"
                                    >
                                    <div></div>
                                    <p>Клиент дает <a href="#" class="btn-big btn-text link-inline link-text">согласие на обработку персональных данных</a></p>
                                </label>
                            </div>
                        </div>
                        <div class="wrapper-checkbox-block area shadow-dark">
                            <div class="wrapper-checkbox inline-block">
                                <label class="checkbox">
                                    <input 
                                        type="checkbox"
                                        validation="required"
                                        v-model="data.persData"
                                    >
                                    <div></div>
                                    <p>Клиент дает согласие на передачу персональных данных в «АО НПФ Эволюция» с целью технического сопровождения оформления договора индивидуального пенсионного плана*</p>
                                </label>
                            </div>
                        </div>
                        <div class="wrapper-checkbox-block area shadow-dark">
                            <div class="wrapper-checkbox inline-block">
                                <label class="checkbox">
                                    <input 
                                        type="checkbox"
                                        validation="optional"
                                        v-model="data.markMail"
                                    >
                                    <div></div>
                                    <p>Клиент дает <a href="#" class="btn-big btn-text link-inline link-text">согласие на маркетинговые рассылки</a> <span>(необязательно)</span></p>
                                </label>
                            </div>
                        </div>
                        <div class="area-buttons btns-end">
                            <div class="area-buttons-block">
                                <router-link 
                                    :to="{name: 'contractId-2'}" 
                                    tag="button" 
                                    :disabled="activeBtn"
                                    class="btn-big primary btn-text"
                                    >
                                    Продолжить
                                </router-link>
                            </div>
                        </div>
                    </FormulateForm>
                </div>
            </div>
        </transition>
    </div>
    
</template>



<script>

export default {
    name: 'v-step-1',
    data(){
        return{
            data: {
                markMail: false,
                pubPerson: false,
                persData: false,
                spouse: false,
                broadcast: false,
                stableFinance: false
            },
            activeBtn: true
        }
    },
    methods: {

        clearCookie(){

            this.$cookies.remove('contract-check_boxes_step_2');
            this.$router.push({name: 'home'})
        },

        reActive(){

            const localData = {...this.data}

            delete localData.markMail

            if(!~Object.values(localData).indexOf(false)){
                return this.activeBtn = false
            }

            this.activeBtn = true
        },
        
        setCookie(){
            this.$cookies.set('contract-check_boxes_step_2', JSON.stringify(this.data));
        }
    },
    mounted(){

        this.$store.dispatch('viewLeftButtonHeader/viewButton', {

            name: 'Выбор продукта',
            visible: true,
            workPath: 'contractId-1',

            postFunc: () => {
                this.$router.push({ name: 'contractId-list' })
            } 
        })

       this.setCookie()
    },
    
    updated(){
        this.reActive()
        this.setCookie()
    }

}

</script>